







































































































import Vue from 'vue';
import Cycle from '../components/calibration/Cycle.vue';

export default Vue.extend({
  components: {
    Cycle,
  },
  data() {
    return {
      code: '',
      currentStep: 0,
      clickCount: 5,
      xprediction: 0,
      yprediction: 0,
      stepList: [
        {
          stepStyle: 'margin-top: 2%; margin-left: 2%',
        },
        {
          stepStyle: 'margin-top: 2%; float: right; margin-right: 2%',
        },
        {
          stepStyle: 'margin-top: 62%; float: left; margin-left: 20%',
        },
        {
          stepStyle: 'margin-top: 62%; float: right; margin-right: 20%',
        },
        {
          stepStyle: 'margin-top: 100%; float: left; margin-left: 20%',
        },
        {
          stepStyle: 'margin-top: 100%; float: right; margin-right: 20%',
        },
      ],
      precisionValue: 0,
      past50PointsX: [] as any,
      past50PointsY: [] as any,
      pointsCollectTimer: null as any,
    };
  },
  //   mounted() {
  //   },
  created() {
    this.code = this.$route.query.code as string;
    this.startTrack();
    this.past50PointsX = [];
    this.past50PointsY = [];
  },
  methods: {
    startCalculate() {
      this.past50PointsX = [];
      this.past50PointsY = [];
      this.pointsCollectTimer = setInterval(async () => {
        const predi = await window.webgazer.getCurrentPrediction();
        // console.log(`predi:${JSON.stringify(predi)}`);
        this.past50PointsX.push(predi.x);
        this.past50PointsY.push(predi.y);
      }, 100);
      setTimeout(() => {
        this.stopStoringPointsVariable();
        window.clearInterval(this.pointsCollectTimer);
      }, 5200);
    },
    storePointsVariable() {
      // window.webgazer.params.storingPoints = true;
    },
    stopStoringPointsVariable() {
      // window.webgazer.params.storingPoints = false;
      const past50 = [this.past50PointsX, this.past50PointsY];
      console.log(`past50: ${JSON.stringify(past50)}`);
      this.precisionValue = this.calculatePrecision(past50);
      this.currentStep = 8;
      window.webgazer.end();
      this.past50PointsX = [];
      this.past50PointsY = [];
    },
    calculatePrecision(past50Array: any[]) {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      // Retrieve the last 50 gaze prediction points
      const x50 = past50Array[0];
      const y50 = past50Array[1];

      // Calculate the position of the point the user is staring at
      const staringPointX = windowWidth / 2;
      const staringPointY = windowHeight / 2;

      const precisionPercentages = new Array(50);
      this.calculatePrecisionPercentages(precisionPercentages, windowHeight, x50, y50, staringPointX, staringPointY);
      const precision = this.calculateAverage(precisionPercentages);

      // Return the precision measurement as a rounded percentage
      return Math.round(precision);
    },
    calculatePrecisionPercentages(precisionPercentages: any[], windowHeight: number, x50: number[], y50: number[], staringPointX: number, staringPointY: number) {
      let x = 0;
      for (x = 0; x < 50; x += 1) {
        // Calculate distance between each prediction and staring point
        const xDiff = staringPointX - x50[x];
        const yDiff = staringPointY - y50[x];
        const distance = Math.sqrt((xDiff * xDiff) + (yDiff * yDiff));

        // Calculate precision percentage
        const halfWindowHeight = windowHeight / 2;
        let precision = 0;
        if (distance <= halfWindowHeight && distance > -1) {
          precision = 100 - ((distance / halfWindowHeight) * 100);
        } else if (distance > halfWindowHeight) {
          precision = 0;
        } else if (distance > -1) {
          precision = 100;
        }

        // Store the precision
        precisionPercentages[x] = precision;
      }
    },
    calculateAverage(precisionPercentages: any[]) {
      let precision = 0;
      for (let x = 0; x < 50; x += 1) {
        precision += precisionPercentages[x];
      }
      precision /= 50;
      return precision;
    },
    quitCalibration() {
      try {
        window.webgazer.end();
      } catch (error) {
        console.log(error);
      }

      if (this.code === '' || this.code === undefined || this.code === null) {
        console.log('empty');
        this.$router.push({
          path: '/',
        });
      } else {
        console.log('not empty');

        this.$router.push({
          path: '/ing',
          query: {
            code: `${this.code}`,
            allowEyeTracking: 'TRUE',
          },
        });
      }
    },
    restartCalibration() {
      this.currentStep = 0;
    },
    cycleClicked() {
      this.clickCount -= 1;
      if (this.clickCount === 0) {
        this.clickCount = 5;
        this.currentStep += 1;
        if (this.currentStep === 7) {
          window.webgazer.end();
        }
      }
    },
    stepPlus() {
      this.currentStep += 1;
      if (this.currentStep === 7) {
        this.storePointsVariable();
      }
    },
    startCalib() {
      this.currentStep = 1;
    },
    clicked() {
      console.log('clicked');
    },
    startTrack() {
      window.webgazer.setGazeListener((data: { x: any; y: any; }|null, elapsedTime: any) => {
        if (data == null) {
          return;
        }
        this.xprediction = data.x; // these x coordinates are relative to the viewport
        this.yprediction = data.y; // these y coordinates are relative to the viewport
        // console.log(`elapsedTime: ${elapsedTime}`); // elapsed time is based on time since begin was called
      }).begin();
      window.webgazer.showFaceOverlay(false);
      window.webgazer.showVideo(false);
      window.webgazer.setVideoViewerSize(1, 1);
      // eslint-disable-next-line prefer-const
      // let child = document.getElementById('webgazerVideoFeed') as any;
      // child.parentNode.removeChild(child);
    },
    doubleClick() { // For handling double click event
      console.log('double');
    },
  },
});
